<template>
  <c-box
    id="landingpage"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Formulir Gizi', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      padding-top="30px"
      padding-bottom="40px"
    >
      <c-box
        w="100%"
        max-width="1200px"
        mx="auto"
        :px="['20px', '0']"
      >
        <c-box text-align="center">
          <c-heading
            as="h2"
            :font-size="['18px','20px']"
            font-weight="700"
            color="black.900"
            text-align="center"
            font-family="roboto"
            :line-height="['27px','30px']"
          >
            Informed Consent
          </c-heading>
        </c-box>
        <c-box
          w="100%"
          max-width="794px"
          mx="auto"
          margin-top="30px"
        >
          <c-box
            w="100%"
            :p="['10px', '20px']"
            rounded="10px"
            :background-color="['white', 'superLightGray.900']"
          >
            <c-box
              class="informed-consent"
              v-html="consent"
            />
          </c-box>
          <c-checkbox
            v-model="isAgreeInformedConsent"
            align-items="flex-start"
            :size="['sm', 'md'].includes(currentBreakpoint) ? 'sm' : 'lg'"
            variant-color="primary"
            color="#000000"
            margin-top="20px"
          >
            Saya menyetujui semua pernyataan diatas
          </c-checkbox>
          <c-checkbox
            v-model="isAgreeForResearch"
            align-items="flex-start"
            :size="['sm', 'md'].includes(currentBreakpoint) ? 'sm' : 'lg'"
            variant-color="primary"
            color="#000000"
            margin-top="20px"
          >
            Saya menyetujui data saya boleh digunakan untuk keperluan
            pengembangan dan penelitian ilmu gizi dan kesehatan dengan
            identitas saya dirahasiakan
          </c-checkbox>
          <c-flex
            margin-top="16px"
            justify-content="center"
          >
            <c-button
              w="100%"
              h="48px"
              max-w="500px"
              border-radius="100px"
              variant-color="primary"
              variant="solid"
              loading-text="Submitting"
              :is-disabled="isDisabled"
              :is-loading="isSubmitting"
              @click="handleSubmit"
            >
              Selanjutnya
            </c-button>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import consent from './InformedConsent.html'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ClientStatement',
  components: {
    BreadcrumbPath,
  },
  mixins: [mixinsCheckBreakpoints()],
  data() {
    return {
      consent,
      isAgreeInformedConsent: false,
      isAgreeForResearch: false,
      isSubmitting: false,
    }
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.informedConsent,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    isDisabled() {
      return !this.isAgreeInformedConsent
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return
        this.isAgreeForResearch = val.isAgreeForResearch
        this.isAgreeInformedConsent = val.isAgreeInformedConsent
      },
    },
    $data: {
      handler: _.debounce(function(newValue) {
        this.setStoreInformedConsent({
          isAgreeForResearch: newValue.isAgreeForResearch,
          isAgreeInformedConsent: newValue.isAgreeInformedConsent,
        })
      }, 1000),
      deep: true,
    },
  },
  created() {
    if (this.currentStep && this.currentStep != 0) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    }
  },
  mounted() {
    this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'informedConsent',
    })
  },
  methods: {
    ...mapMutations({
      setStoreInformedConsent: 'kuisioner/setInformedConsent',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    async handleSubmit() {
      try {
        this.isSubmitting = true
        await this.$store.dispatch('kuisioner/setInformedConsent', {
          programId: this.$route.params?.programId,
          answers: [],
          isDraft: true,
          value: {
            isAgreeForResearch: this.isAgreeForResearch,
            isAgreeInformedConsent: this.isAgreeInformedConsent,
          },
          step: 1,
        })

        await this.$store.dispatch('kuisioner/updateCurrentStep', 1)
        await this.$store.commit('kuisioner/resetInformedConsent')
        await this.$router.push({
          name: 'client.kuisioner1',
          params: this.$route.params,
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>

<style scoped>
.informed-consent::v-deep {
  font-family: Roboto, sans-serif;
}

.informed-consent::v-deep h1 {
  font-weight: bold;
  font-size: 18px;
}

.informed-consent::v-deep p {
  text-align: justify;
  margin: 14px 0;
}

.informed-consent::v-deep ol li {
  margin: 8px 0 8px 20px;
}

@media (max-width: 767px) {
  .informed-consent::v-deep h1 {
    margin-top: 8px;
    font-size: 14px;
  }

  .informed-consent::v-deep p,
  .informed-consent::v-deep ol li {
    font-size: 12px;
  }
}
</style>
